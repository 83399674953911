<template>
  <div class="landing_0223">
    <section>
      <div
        class="caption-wrapper img-container"
        style="background-image: url('/imgs/landing_1122/section_1.png')"
      >
        <div class="landing_topbar">
          <div class="container">
            <div class="brand">
              <router-link @click="onClickLink" to="/">
                <img src="/svgs/logo.svg" alt="logo" />
              </router-link>
            </div>

            <nav :class="{ active: navbarActive }">
              <ul>
                <li>
                  <router-link @click="onClickLink" to="/campus/barcelona">
                    Кампус в Барселоне
                  </router-link>
                </li>
                <li>
                  <router-link @click="onClickLink" to="/campus/madrid">
                    Кампус в Мадриде
                  </router-link>
                </li>
              </ul>
              <button class="close" @click="toggleNavbar(false)">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </nav>

            <button class="collapse" @click="toggleNavbar(true)">
              <i class="fa-solid fa-bars"></i>
            </button>
          </div>
        </div>
        <div class="caption">
          <small>
            Центр, аккредитованный институтом Сервантеса и Торговой палатой
          </small>
          <img
            src="/imgs/landing_0223/logos_camara.png"
            alt="Logos"
            class="logos_camara"
          />

          <h1 class="mt-5 mb-6">
            Получите студенческую визу в испанской языковой школе NL college,
            аккредитованной Институтом Сервантеса, и сделайте первый шаг к жизни
            в Европе!
          </h1>

          <button class="btn btn-yellow btn-ru-landing" @click="goToForm">
            Хочу узнать подробнее
          </button>
        </div>
      </div>
    </section>

    <section>
      <div class="container py-7">
        <div class="row">
          <div class="col-md-8">
            <h4 class="mb-5">
              Студенческая виза для годового курса испанского языка может быть
              1-м шагом на пути переезда в Европу для всей семьи или получения
              образования в Европе
            </h4>
            <ul class="mb-5">
              <li>
                <p>
                  Нет ограничений по возрасту. Вы можете получить студенческую
                  визу для изучения испанского и первого шага на пути переезда в
                  Европу в любом возрасте.
                </p>
              </li>
              <li>
                <p>
                  Если вы хотите переехать с родителями-пенсионерами или с
                  детьми, студенческая виза - отличное решение!
                </p>
              </li>
              <li>
                <p>Через 1 год вы сможете работать!</p>
              </li>
              <li>
                <p>
                  Какой следующий шаг после 1 года студенческой визы на языковых
                  курсах? Через 1 год вы сможете либо продлить студенческую
                  визу, либо модифицировать ее на рабочую визу, либо открыть
                  свой бизнес. Делая первый шаг, вы можете продумать стратегию
                  переезда шаг за шагом, год за годом, план получения ВНЖ и
                  резиденции.
                </p>
                <p>Мы рассматриваем каждую ситуацию индивидуально.</p>
              </li>
            </ul>

            <button class="btn btn-yellow btn-ru-landing" @click="goToForm">
              Получить бесплатную консультацию и мой план переезда в Испанию
            </button>
          </div>
          <div class="col-md-2 offset-md-2">
            <div class="d-flex flex-md-column gap-5">
              <div
                class="img-container img-container-circle"
                style="background-image: url('/imgs/landing_0223/02.jpg')"
              />
              <div
                class="img-container img-container-circle"
                style="background-image: url('/imgs/landing_0223/01.jpg')"
              />
              <div
                class="img-container img-container-circle"
                style="background-image: url('/imgs/landing_0223/03.jpg')"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div
        class="img-container text-white py-7"
        style="background-image: url('/imgs/landing_0223/section3.png')"
      >
        <div class="container">
          <h2 class="mb-5">Образование в Испании</h2>
          <h4 class="mb-4">
            Если Вы хотите дать детям европейское образование, то Испания -
            хороший выбор. Годовой курс испанского языка, чтобы получить уровень
            В2 и поступать в государственные испанские университеты.
          </h4>
          <h4 class="mb-6">
            Какие шаги нужны для поступления в испанский государственный
            университет после школы или уже имея университетский диплом?
          </h4>

          <button class="btn btn-yellow btn-ru-landing" @click="goToForm">
            Получить бесплатную консультацию об образовании в Испании
          </button>
        </div>
      </div>
    </section>

    <section
      class="img-container"
      style="background-image: url('/imgs/landing_0223/rect_01.png')"
    >
      <div class="container text-white px-2 py-6 p-md-7">
        <h2 class="mb-4">
          Как получить студенческую визу D для годового курса испанского языка?
        </h2>
        <h5 class="mb-5">Два пути получить студенческую визу</h5>

        <div class="row text-left mb-5">
          <div class="col-md-6 mb-4 mb-md-0">
            <div
              class="card card-white minh-3 h-100 d-flex justify-content-center align-items-center p-3"
            >
              <div class="card-body">
                <p>
                  <b>
                    B визовом центре, консульстве или посольстве Испании в вашей
                    стране.
                  </b>
                </p>
                <p>Время рассмотрения заявления зависит от страны.</p>
                <p>
                  Первоначальная цена годового курса 4010€. Сейчас мы предлагаем
                  интенсивный курс испанского языка за 3300€ (регистрационный
                  взнос + 1 комплект учебников + испанская медицинская страховка
                  для годовой студенческой визы включены).
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="card card-white minh-3 h-100 d-flex justify-content-center align-items-center p-3"
            >
              <div class="card-body">
                <p>
                  <b
                    >Вам нужно получить шенгенскую туристическую визу на 90
                    дней, приехать в Испанию и подать документы на студенческую
                    визу из Испании.
                  </b>
                </p>
                <p>
                  На момент подачи документов вы должны иметь минимум 31 день до
                  даты окончания туристической визы.
                </p>
                <p>
                  Цена годового курса была 4010€, и сейчас мы предлагаем годовой
                  интенсивный курс испанского за 2500€ (регистрационный взнос +
                  1 комплект учебников включены).
                </p>
              </div>
            </div>
          </div>
        </div>

        <a
          href="/files/Student Visa Long-term-2.pdf"
          download="Student Visa Long-term-2.pdf"
          target="_blank"
          class="underlined"
        >
          <i class="fa fa-arrow-right mr-2"></i>
          Какие документы нужны для испанской студенческой визы. Скачать
          бесплатно!
        </a>
      </div>
    </section>

    <section>
      <div class="container py-7">
        <div class="row">
          <div class="col-md-8">
            <h4 class="mb-5">Почему студенческая виза с NL College?</h4>
            <ul class="mb-5">
              <li>
                <p>Аккредитация Института Сервантеса.</p>
              </li>
              <li>
                <p>
                  Кампусы в Мадриде и Барселоне. Выбери, где ты ты хочешь жить!
                </p>
              </li>
              <li>
                <p>
                  Единственная школа, которая предоставляет учебные материалы,
                  поддержку и объяснение испанской грамматики на русском языке.
                </p>
              </li>
              <li>
                <p>
                  Полное сопровождение в подаче документов на студенческую визу
                  и получение NIE.
                </p>
              </li>
              <li>
                <p>
                  Испанская медицинская страховка для годовой студенческой визы
                  по льготной цене в лучших страховых компаниях Испании
                  (Adeslas, Sanitas, Asisa).
                </p>
              </li>
              <li>
                <p>
                  Бронирование апартаментов или хостела для проживания и
                  предоставление сертификата о регистрации для подачи документов
                  на визу.
                </p>
              </li>
            </ul>

            <button class="btn btn-yellow btn-ru-landing" @click="goToForm">
              Хочу узнать подробнее
            </button>
          </div>
          <div class="col-md-2 offset-md-2">
            <div class="d-flex flex-md-column gap-5">
              <div
                class="img-container img-container-circle"
                style="background-image: url('/imgs/landing_0223/02.jpg')"
              />
              <div
                class="img-container img-container-circle"
                style="background-image: url('/imgs/landing_0223/01.jpg')"
              />
              <div
                class="img-container img-container-circle"
                style="background-image: url('/imgs/landing_0223/03.jpg')"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="img-container"
      style="background-image: url('/imgs/landing_0223/rect_01.png')"
    >
      <div class="container text-white px-2 py-6 p-md-7">
        <h2 class="mb-5">
          С NL College вы можете получить студенческую визу 2 типов
        </h2>

        <div class="row text-left mb-5">
          <div class="col-md-6 mb-4 mb-md-0">
            <div
              class="card card-white minh-3 h-100 d-flex justify-content-center align-items-center p-3"
            >
              <div class="card-body">
                <h5>Студенческая виза без права на работу</h5>
                <p><i> (право на работу запрашивается дополнительно)</i></p>
                <p>
                  B визовом центре, консульстве или посольстве Испании в вашей
                  стране. Время рассмотрения заявления зависит от страны.
                </p>
                <p>
                  Первоначальная цена годового курса 4010€. Сейчас мы предлагаем
                  интенсивный курс испанского языка за 3300€ (регистрационный
                  взнос + 1 комплект учебников + испанская медицинская страховка
                  для годовой студенческой визы включены).
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="card card-white minh-3 h-100 d-flex justify-content-center align-items-center p-3"
            >
              <div class="card-body">
                <h5>Студенческая виза с правом на работу</h5>
                <p>
                  Возможность получить студенческую визу с разрешением на работу
                  30 часов в неделю (на курсе подготовки к поступлению в
                  испанские государственные университеты)
                </p>
                <p>
                  6 месяцев интенсивного обучения испанскому языку + 5 месяцев
                  подготовки по 6 дисциплинам к экзамену EvAU (selectividad) +
                  омологация аттестата и подача заявления на поступление в 12
                  государственных университетов (на выбор из 53) = Годовая
                  студенческая виза с правом на работу 30 часов для подготовки к
                  поступлению в университет (актуальна только для тех, у кого
                  нет диплома о высшем образовании).
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container py-7">
        <div class="row justify-content-between">
          <div class="col-md-3">
            <h4 class="text-primary minh-1">Мероприяти в школе</h4>
            <div
              class="img-container img-container-circle mb-5"
              style="background-image: url('/imgs/landing_0223/07.jpg')"
            ></div>
            <p>
              Возможность изучать Испанию и местную культуру, побывать в местах
              для не-туристов и практиковать испанский после уроков. Вы сможете
              побывать на уникальных экскурсиях, учиться танцевать, рисовать,
              заниматься йогой или учиться серфингу, побывать в ресторанах,
              барах, куда ходят только местные испанцы и получить опыт, который
              никогда бы не смогли получить как туристы.
            </p>
          </div>
          <div class="col-md-3">
            <h4 class="text-primary minh-1">Каникулы</h4>
            <div
              class="img-container img-container-circle mb-5"
              style="background-image: url('/imgs/landing_0223/08.jpg')"
            ></div>
            <p>
              Возьмите каникулы в любое удобное вам время. <br />
              В наших долгосрочных курсах мы предлагаем студентам возможность
              взять каникулы в любой период обучения, помимо основных
              праздничных дней, устанавливаемых государством.
            </p>
          </div>
          <div class="col-md-3">
            <h4 class="text-primary minh-1">Проживание</h4>
            <div
              class="img-container img-container-circle mb-5"
              style="background-image: url('/imgs/landing_0223/09.jpg')"
            ></div>
            <p>
              Наши партнеры предлагают проживание как в Мадриде так и в
              Барселоне, на любой вкус и кошелек.
            </p>
            <p>
              Цены варьируются от 450€ до 1080€ (комната на
              двоих/индивидуальная/апартаменты).
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="footer">
      <div
        class="img-container text-white py-7"
        style="background-image: url('/imgs/landing_0223/section3.png')"
      >
        <div class="container">
          <h3 class="mb-5">
            Студенческая виза для годового курса испанского языка может быть 1-м
            шагом на пути переезда в Европу для всей семьи или получения
            образования в Европе
          </h3>

          <form class="pt-4" id="form" action="#form" @submit="onSubmit">
            <input
              type="text"
              placeholder="Ваше имя"
              required
              name="your-name"
              v-model="form.name"
            />
            <input
              type="text"
              placeholder="Ваш email"
              required
              name="your-email"
              v-model="form.email"
            />
            <input
              type="text"
              placeholder="Ваш номер Whatsapp или Telegram"
              required
              name="your-phone"
              v-model="form.phone"
            />

            <button type="submit" class="btn btn-sm btn-block btn-yellow">
              Отправить
            </button>
          </form>

          <router-link @click="onClickLink" to="/"
            ><img src="/svgs/logo_2.svg" alt="" class="logo_2 mb-5"
          /></router-link>

          <p class="mb-2">
            <router-link to="/campus/barcelona">Кампус в Барселоне</router-link>
            |
            <router-link to="/campus/madrid">Кампус в Мадриде</router-link>
          </p>
          <a class="mb-5" href="mailto:info@nlcollege.es">
            info@nlcollege.es
          </a>

          <div class="socials">
            <a href=""><i class="fa-brands fa-instagram"></i></a>
            <a href=""><i class="fa-brands fa-facebook"></i></a>
            <a href=""><i class="fa-brands fa-tiktok"></i></a>
            <a href=""><i class="fa-brands fa-linkedin"></i></a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import axios from "axios";
// import { createPmlScript, removePmlScript } from "@/utils/pmlHelper.js";

export default {
  setup() {
    let navbarActive = ref(false);

    const toggleNavbar = (active) => {
      navbarActive.value = active;
    };
    const onClickLink = () => {
      if (navbarActive.value) navbarActive.value = false;
    };

    return {
      navbarActive,
      toggleNavbar,
      onClickLink,
    };
  },
  // beforeUnmount() {
  //   removePmlScript();
  // },
  // mounted() {
  //   createPmlScript("russia");
  // },
  data: () => ({
    form: {
      name: null,
      email: null,
      phone: null,
    },
  }),
  methods: {
    goToForm() {
      document.querySelector("#form").scrollIntoView();
    },
    onSubmit(e) {
      e.preventDefault();

      axios
        .get("https://api.pumpmylead.com/integrations/informs/v1/webhook", {
          params: {
            "your-phone": this.form.phone,
            "your-email": this.form.email,
            "your-name": this.form.name,
            token: "app2be230283kkym11956p246942yog8e",
          },
        })
        .then(() => {
          this.clearForm();
          this.formSuccessMessage();
        });
    },
    clearForm() {
      this.form = {
        name: null,
        email: null,
        phone: null,
      };
    },
    formSuccessMessage() {
      this.$toast.success("Большое спасибо, ваше сообщение отправлено");
    },
  },
};
</script>
